<script setup lang="ts">
    import { dragscroll } from 'vue-dragscroll';

    interface BreadcrumbsProps {
        items: {
            title: string;
            slug: string;
        }[];
    }
    const props = defineProps<BreadcrumbsProps>();

    const localePath = useLocalePathWoom();

    const isLastBreadcrumb = (index: number) => index + 1 === props.items.length;

    const vDragscroll = dragscroll;
</script>
<template>
    <div class="atm-breadcrumbs overflow-hidden">
        <ul
            v-if="Array.isArray(items) && items.length"
            v-dragscroll.x
            class="scrollbar flex h-14 items-center gap-2 overflow-x-auto font-mono text-sm">
            <li
                v-for="(item, index) in items"
                :key="index"
                class="flex items-center gap-2">
                <nuxt-link
                    :to="isLastBreadcrumb(index) ? '#main' : localePath(item.slug)"
                    :aria-current="isLastBreadcrumb(index) ? 'page' : undefined"
                    :class="[{ 'text-woom-red hover:no-underline': isLastBreadcrumb(index) }, 'whitespace-nowrap hover:underline']">
                    <renderer-html :content="woomTextFormat(item.title)" />
                </nuxt-link>
                <woom-icon-chevron-right
                    v-if="!isLastBreadcrumb(index)"
                    width="14"
                    height="14" />
            </li>
        </ul>
    </div>
</template>
